import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useStore from "../../../context/useStore";
import dataService from "../../../helpers/dataService";

const useFileViewer = ({ filesArray, currentFileId }) => {
  const [state, dispatch] = useStore();
  const { FILE_TYPES } = state.constants.items;
  const fileTypes = FILE_TYPES;

  const [currentslide, setCurrentSlide] = useState(0);
  const [files, setFiles] = useState([]);
  const [blobs, setBlobs] = useState([]);
  const [isSavingComment, setIsSavingComment] = useState(false);
  const [isGettingMedia, setIsGettingMedia] = useState(false);

  useEffect(() => {
    const initialSlideIndex = filesArray.findIndex(
      (f) => f.id == currentFileId
    );
    if (initialSlideIndex >= 0) {
      setCurrentSlide(initialSlideIndex);
    }

    setFiles(filesArray);
  }, [filesArray]);

  useEffect(() => {
    localStorage.setItem("files", JSON.stringify(files));
  }, [files]);

  const goToPrevSlide = () => {
    setCurrentSlide(currentslide === 0 ? files.length - 1 : currentslide - 1);
  };

  const goToNextSlide = () => {
    setCurrentSlide(currentslide === files.length - 1 ? 0 : currentslide + 1);
  };
  const goToSlideIndex = (index) => {
    setCurrentSlide(index);
  };

  const handleChange = (e) => {
    var { value } = e.target;
    setFiles(
      files.map((f, fk) => (fk == currentslide ? { ...f, comment: value } : f))
    );
  };

  const sendComment = (file) => {
    setIsSavingComment(true);
    dataService.post(
      `files/${file.id}/comments`,
      {
        content: file.comment,
      },
      (datas) => {
        setFiles(
          files.map((f, fk) =>
            fk == currentslide
              ? { ...f, comment: "", comments: datas.comments }
              : f
          )
        );
      },
      (err) => {
        toast.error("Une erreur s'est produite");
      },
      () => setIsSavingComment(false)
    );
  };

  var currentFile = files.length ? files[currentslide] : null;

  return {
    fileTypes,
    files,
    setFiles,
    blobs,
    setBlobs,
    currentslide,
    setCurrentSlide,
    goToNextSlide,
    goToPrevSlide,
    goToSlideIndex,
    handleChange,
    sendComment,
    isSavingComment,
    isGettingMedia,
    setIsGettingMedia,
    currentFile,
  };
};

export default useFileViewer;
