import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import Loader from "../../common/Loader";
import { Link } from "react-router-dom";
import dataService from "../../../helpers/dataService";
const ActivateAccount = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isActivated, setIsActivated] = useState(false);
  const [errors, setErrors] = useState(false);

  useEffect(() => {
    if (props.match.params.token) {
      dataService.post(
        `user-customers/activate-account`,
        {
          token: props.match.params.token,
        },
        (datas) => setIsActivated(true),
        setErrors,
        () => setIsLoading(false)
      );
    }
  }, []);

  return (
    <Page className="d-center" style={{ minHeight: "100vh" }}>
      <div className="row">
        <div
          style={{ borderRadius: 5 }}
          className="col-12 text-center shadow p-4  m-auto bg-white"
        >
          {isLoading ? (
            <>
              <p>Activation de votre compte en cours...</p>
              <Loader />
            </>
          ) : isActivated ? (
            <div className="text-secondary">
              <i className="fa fa-check fa-3x" />
              <h3 className="py-3">Votre compte a bien été activé.</h3>
              <p>Vous pouvez à présent vous connecter.</p>
              <Link to="/" className="btn btn-primary d-flex d-center">
                Se connecter
              </Link>
            </div>
          ) : (
            <>
              <i className="fa fa-times fa-3x text-danger" />
              <p className="text-danger mb-0 mt-3">
                Un problème est survenu lors de l'activation de votre compte.
              </p>
              <p className="mb-0 text-danger">{errors.other}</p>
              <Link
                to="/"
                className="btn btn-primary mt-3 d-flex align-items-center justify-content-center w-75 mx-auto"
              >
                Revenir à l'accueil
              </Link>
            </>
          )}
        </div>
      </div>
    </Page>
  );
};

export default ActivateAccount;
