import YouTube from "react-youtube-embed";
import { modalActions } from "../../../../context/actions/modalActions";
import useStore from "../../../../context/useStore";

const VideoViewer = () => {
  const [state, dispatch] = useStore();
  const mActions = modalActions(state, dispatch);
  return (
    <div className="w-100 h-100 d-center bg-dark flex-column">
      <div className="mb-5 text-white text-center" style={{ fontSize: 22 }}>
        <strong>Présentation de l'espace client</strong>
      </div>
      <div
        style={{
          width: "min(100% , 600px)",
        }}
      >
        <YouTube id={"x-TaUAa1cvs"} />
      </div>
      <button
        className="btn btn-primary mt-5"
        onClick={() => mActions.updateModal({ isOpen: false, content: null })}
      >
        Accéder à mon espace
      </button>
    </div>
  );
};

export default VideoViewer;
