import Loader from "../../common/Loader";
import {
  useStripe,
  useElements,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import CardForm from "./CardForm";
import useStoreState from "./Context/useStoreState";

const PaymentCb = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { items, items_actions, state } = useStoreState();
  const { isLoadingPaying } = state;
  const { payment, utils, errors } = items;
  const { paymentIntent } = utils;
  const {
    setErrors,
    setIsLoading,
    setPaymentIntent,
    setPaymentOk,
  } = items_actions;

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setErrors({});
    setIsLoading("PAYING", true);

    const result = await stripe.confirmCardPayment(paymentIntent.clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
      },
      setup_future_usage: "off_session",
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      setErrors({ payment: result.error.message });
      setIsLoading("PAYING", false);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        setIsLoading("PAYING", false);
        setPaymentOk(true);
        setPaymentIntent(result.paymentIntent);

        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardForm />
      <div className="d-flex justify-content-end mt-2">
        {isLoadingPaying ? (
          <Loader
            className="flex-column mt-4"
            text="Paiement en cours"
            textClassName="mt-2"
          />
        ) : (
          <>
            {errors.payment && (
              <div className="alert alert-danger mb-0">{errors.payment}</div>
            )}
            <button className="btn btn-primary  py-2" disabled={!stripe}>
              <i className="fa fa-lock mr-2" />
              PAYER {Math.round((payment.amount / 100) * 100) / 100} €
            </button>
          </>
        )}
      </div>
    </form>
  );
};

export default PaymentCb;
