import React, { useState } from "react";
import { VerticalTimeline } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./Timeline.scss";
import TimelineEventCycle from "./TimelineEventCycle";

const TimelineCycle = ({ events }) => {
  const [layout, setLayout] = useState("1-column");

  return (
    <div style={{ borderRadius: "8px" }}>
      <VerticalTimeline layout={layout}>
        {events.map((ev, evk) => (
          <TimelineEventCycle key={"ev" + evk} event={ev} layout={layout} />
        ))}
      </VerticalTimeline>
    </div>
  );
};

export default TimelineCycle;
