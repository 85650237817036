import FileSlide from "./FileSlide";
import Nav from "./Nav";
import useFileViewer from "./useFileViewer";
import Control from "../Controls/Control";
import SaveBtn from "../SaveBtn/SaveBtn";
import Loader from "../Loader";

const FilePreview = ({ filesArray, currentFileId }) => {
  const {
    files,
    blobs,
    setBlobs,
    currentslide,
    setCurrentSlide,
    goToNextSlide,
    goToPrevSlide,
    handleChange,
    sendComment,
    isSavingComment,
    isGettingMedia,
    setIsGettingMedia,
    currentFile,
    goToSlideIndex,
  } = useFileViewer({ filesArray, currentFileId });

  return !currentFile ? (
    <Loader />
  ) : (
    <>
      <div className="">
        <div className="viewer bg-dark" style={{ height: "100vh" }}>
          <FileSlide
            file={currentFile}
            blobs={blobs}
            setBlobs={setBlobs}
            setIsGettingMedia={setIsGettingMedia}
            handleChange={handleChange}
            isSavingComment={isSavingComment}
            sendComment={sendComment}
          />
        </div>

        <Nav
          goToPrevSlide={goToPrevSlide}
          goToNextSlide={goToNextSlide}
          goToSlideIndex={goToSlideIndex}
          currentslide={currentslide}
          files={files}
          setCurrentSlide={setCurrentSlide}
          isGettingMedia={isGettingMedia}
        />
      </div>
    </>
  );
};

export default FilePreview;
