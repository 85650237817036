import PaymentMethod from "./PaymentMethod";
import PaymentElement from "./PaymentElement";
import PaymentElement2 from "./PaymentElement2";
import useStoreState from "./Context/useStoreState";
import PaymentSuccess from "./PaymentSuccess";
import PaymentInvalid from "./PaymentInvalid";

const PaymentForm = () => {
  const { items } = useStoreState();
  const { payment } = items;
  const { paymentOk } = items.utils;

  return payment.status == "WAITING" ? (
    paymentOk ? (
      <PaymentSuccess />
    ) : (
      <div>
        <PaymentMethod />
        {payment.providerData?.accountKey == "LITHOTE" ? (
          <PaymentElement />
        ) : (
          <PaymentElement2 />
        )}
      </div>
    )
  ) : (
    <PaymentInvalid />
  );
};

export default PaymentForm;
