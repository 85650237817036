import useStore from "../../../context/useStore";
import useStoreState from "./Context/useStoreState";

const PaymentMethod = () => {
  const { gState, state, items, items_actions } = useStoreState();
  const { paymentMethod } = items.utils;
  const { PAYMENT_METHODS } = gState.constants.items;

  const handleChangePm = (id) => {
    items_actions.setPaymentMethod(id);
  };
  return (
    <div className="d-center flex-column custom-card mb-2">
      <div className="mb-2">
        <strong>Méthode de paiement</strong>
      </div>
      <div className="d-flex flex-row justify-content-around align-items-center flex-wrap w-100">
        {PAYMENT_METHODS.filter((p) => p.isStripe).map((pm) => (
          <button
            onClick={() => handleChangePm(pm.id)}
            className={`btn mx-1 d-center flex-fill ${
              paymentMethod == pm.id ? "btn-primary" : "btn-default"
            }`}
            key={`paymentmethod${pm.id}`}
          >
            <i className={`fa fa-${pm.icon} mr-2`} />
            {pm.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default PaymentMethod;
