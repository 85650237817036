import useStoreState from "./Context/useStoreState";
import PaymentCb from "./PaymentCb";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import dataService from "../../../helpers/dataService";
import { useEffect } from "react";
import Loader from "../../common/Loader";
import PaymentSepa from "./PaymentSepa";
const stripePromise = loadStripe(process.env.REACT_APP_COMPANY2_STRIPE_TOKEN);

const PaymentElement = () => {
  console.log("2nd account");
  const { items, state, items_actions } = useStoreState();
  const { setPaymentIntent, setErrors, setIsLoading } = items_actions;
  const { isLoadingStripe } = state;
  const { payment } = items;
  const { paymentMethod } = items.utils;

  useEffect(() => {
    if (paymentMethod) {
      getPaymentIntent();
    }
  }, [paymentMethod]);

  const getPaymentIntent = () => {
    setIsLoading("STRIPE", true);
    dataService.post(
      `payments/${payment.id}/payment-intent`,
      {
        paymentMethod,
      },
      setPaymentIntent,
      setErrors,
      () => setIsLoading("STRIPE", false)
    );
  };

  return paymentMethod ? (
    isLoadingStripe ? (
      <Loader />
    ) : (
      <div className="custom-card">
        {paymentMethod == "CARD" && (
          <Elements stripe={stripePromise}>
            <PaymentCb />
          </Elements>
        )}
        {paymentMethod == "SEPA" && (
          <Elements stripe={stripePromise}>
            <PaymentSepa />
          </Elements>
        )}
      </div>
    )
  ) : null;
};

export default PaymentElement;
