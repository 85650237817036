import { Link } from "react-router-dom";

const PaymentSuccess = () => {
  return (
    <>
      <div
        className="mx-auto d-center rounded-circle p-5 bg-white shadow-sm flex-column text-center"
        style={{
          width: 300,
          height: 300,
          overflow: "hidden",
        }}
      >
        <i className="fa fa-check-double animated bounceInDown fa-3x text-success mb-4" />
        <div
          className="animated bounceInUp "
          style={{
            fontSize: 28,
          }}
        >
          <strong>Merci !</strong>
        </div>
        <div className="animated fadeInUp delay-1s">
          <small>Paiement accepté</small>
        </div>
      </div>
      <div className="w-100 d-center flex-column mt-3">
        <small
          className="text-center mb-2 animated fadeIn delay-2s"
          style={{
            maxWidth: 300,
          }}
        >
          Vous pouvez télécharger un reçu du paiement de cette échéance dans la
          section "Paiement" du projet.
        </small>
        <Link to="/" className="btn btn-link animated fadeInUp delay-2s">
          <i className="fa fa-arrow-left mr-2" />
          Revenir à l'accueil
        </Link>
      </div>
    </>
  );
};

export default PaymentSuccess;
