import AnnexeForm from "./AnnexeForm";
import AnnexeList from "./AnnexeList";
import useAnnexe from "./useAnnexe";

const AnnexesSection = ({ annexes, projectId, searchProjects }) => {
  const {
    existingFiles,
    setExistingFiles,
    deleteAnnexeFile,
    binary,
    setBinary,
    isLoading,
    newFile,
    setNewFile,
    saveNewAnnexeFile,
  } = useAnnexe({ annexes, projectId, searchProjects });

  return (
    <div className="row">
      <div className="col-12">
        <AnnexeList
          existingFiles={existingFiles}
          setExistingFiles={setExistingFiles}
          deleteAnnexeFile={deleteAnnexeFile}
        />
        <div className=" mt-3 mb-2">
          <AnnexeForm
            newFile={newFile}
            setNewFile={setNewFile}
            isLoading={isLoading}
            saveNewAnnexeFile={saveNewAnnexeFile}
          />
        </div>
      </div>
    </div>
  );
};

export default AnnexesSection;
