import React, { useState, useEffect } from "react";
import Avatar from "../../common/Avatar/Avatar";
import useStore from "../../../context/useStore";
import ImageFileCrop from "../../common/ImageFileCrop/ImageFileCrop";
import Control from "../../common/Controls/Control";
import Axios from "axios";
import { API_URL } from "../../../config";
import Loader from "../../common/Loader";
import { toast } from "react-toastify";
import tools from "../../../helpers/tools";

const UserInfo = () => {
  const [state, dispatch] = useStore();
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [canSave, setCanSave] = useState(true);
  const [passwordsEqual, setPasswordsEqual] = useState(true);
  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
    passwordConfirm: "",
  });
  const [modifyAvatar, setModifyAvatar] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    Axios.get(API_URL + "user-customers/me")
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setPasswordsEqual(user.password === user.passwordConfirm);
  }, [user.password, user.passwordConfirm]);

  const updateInput = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };
  const save = () => {
    setIsLoading(true);
    Axios.patch(API_URL + "user-customers/me", user)
      .then((res) => {
        setUser(res.data);
        setErrors({});
        toast.success("Vos informations ont bien été mises à jour");
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
          toast.error(err.response.data.message);
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  };
  const savePassword = () => {
    setIsLoading(true);
    Axios.patch(API_URL + "user-customers/me/password", {
      password: user.password,
    })
      .then((res) => {
        setErrors({});
        toast.success("Votre mot de passe a bien été modifié");
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
          toast.error(err.response.data.message);
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  };
  return modifyAvatar ? (
    <ImageFileCrop setModifyAvatar={setModifyAvatar} />
  ) : (
    <>
      <div className="col-12 position-relative d-flex flex-column flex-md-row justify-content-start align-items-center">
        {/* <div
          className="position-relative"
          onClick={() => setModifyAvatar(true)}
          style={{ cursor: "pointer" }}
        >
          <Avatar width={152} height={152} id={state.auth.user.id} />
        </div> */}
        <h2 className="pt-3" style={{ textTransform: "capitalize" }}>
          {user.firstname} {user.lastname}
        </h2>
      </div>
      <div className="col-12 mt-4">
        <Control
          label="Nom"
          type="text"
          name="lastname"
          value={user.lastname}
          change={updateInput}
          error={errors}
          setError={setErrors}
          required={true}
        />
        <Control
          label="Prénom"
          type="text"
          name="firstname"
          value={user.firstname}
          change={updateInput}
          error={errors}
          setError={setErrors}
          required={true}
        />
        <Control
          label="Email"
          type="email"
          name="email"
          value={user.email}
          change={updateInput}
          error={errors}
          setError={setErrors}
          required={true}
        />
        <Control
          label="Téléphone"
          type="tel"
          name="phone"
          value={user.phone}
          change={updateInput}
          error={errors}
          setError={setErrors}
          required={true}
        />

        <div className="d-flex align-items-center justify-content-md-between justify-content-center flex-wrap">
          <button
            className={`btn btn-default mt-2 animated fast ${
              showPassword ? "fadeOut" : "fadeIn"
            }`}
            onClick={() => setShowPassword((s) => !s)}
          >
            {isLoading ? <Loader color="white" /> : "Changer mon mot de passe"}
          </button>
          <button className="btn btn-primary mt-2" onClick={save}>
            {isLoading ? (
              <Loader color="white" />
            ) : (
              "Mettre à jour mes informations"
            )}
          </button>
        </div>
      </div>

      {showPassword && (
        <div className={`col-12 mt-4 animated faster fadeInUp `}>
          <Control
            label="Mot de passe"
            type="password"
            name="password"
            value={user.password}
            change={updateInput}
            error={errors}
            setError={setErrors}
            autocomplete="new-password"
            required={true}
          />

          <Control
            label="Retapez le mot de passe (identique au 1er)"
            type="password"
            name="passwordConfirm"
            value={user.passwordConfirm}
            change={updateInput}
            error={errors}
            setError={setErrors}
            isValid={
              user.passwordConfirm == user.password &&
              user.password &&
              user.password != ""
            }
            required={true}
          />
          <div className="text-center text-md-right">
            <button
              className="btn btn-primary"
              disabled={
                user.passwordConfirm != user.password ||
                !user.password ||
                user.password == ""
              }
              onClick={savePassword}
            >
              {isLoading ? (
                <Loader color="white" />
              ) : (
                "Mettre à jour mon mot de passe"
              )}
            </button>
          </div>
        </div>
      )}
      <div className="col-12 mt-2">
        {errors.other && (
          <div className="alert alert-danger text-center">{errors.other}</div>
        )}
      </div>
    </>
  );
};

export default UserInfo;
