export const getInitConfig = (fetchId) => {
  return [
    {
      name: `payment`,
      fetchOnLoad: true,
      paths: {
        page: "payments",
        get: `payments/${fetchId}`,
      },
      initialState: {
        name: "",
        description: "",
        client: "",
      },
    },
    {
      name: "user",
      fetchOnLoad: true,
      paths: {
        page: "user",
        get: "user-customers/me",
      },
    },
    {
      name: "utils",
      fetchOnLoad: false,
      initialState: {
        paymentMethod: "",
      },
    },
    { name: "errors", fetchOnLoad: false, initialState: {} },
  ];
};
