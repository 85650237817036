import { useEffect, useState } from "react";
import TimelineCycle from "../../../common/Timeline/TimelineCycle";
import AnnexesSection from "./AnnexesSection/AnnexesSection";
import DevisSection from "./DevisSection/DevisSection";
import DocumentsSection from "./DocumentsSection/DocumentsSection";
import NeedsFormsSection from "./NeedsFormsSection/NeedsFormsSection";
import PaymentsSection from "./PaymentsSection/PaymentsSection";

const ProjectList = ({ props }) => {
  const { projects, searchProjects, openedProjectId } = props;

  const [projectsOpen, setProjectsOpen] = useState([]);

  const handleOpen = (projectId) => {
    if (projectsOpen.includes(projectId)) {
      setProjectsOpen(projectsOpen.filter((p) => p != projectId));
    } else {
      setProjectsOpen([...projectsOpen, projectId]);
    }
  };

  useEffect(() => {
    if (projects.length == 1) {
      setProjectsOpen([projects[0].id]);
    }
  }, [projects]);

  useEffect(() => {
    if (openedProjectId) {
      setProjectsOpen([...projectsOpen, openedProjectId]);
    }
  }, [openedProjectId]);

  return projects.map((project) => {
    const isOpen = projectsOpen.includes(project.id);
    return (
      <div className=" mb-3 custom-card">
        <div
          className="d-flex justify-content-between align-items-center cursor-pointer"
          onClick={() => handleOpen(project.id)}
        >
          <div>
            <strong
              className="text-primary"
              style={{
                fontSize: 24,
              }}
            >
              {project.name}
            </strong>
            <div>
              <p className="mb-0">{project.description}</p>
            </div>
          </div>
          <i className={`fa text-dark fa-2x ${isOpen ? "fa-chevron-up" : "fa-chevron-down"}`} />
        </div>
        <div
          style={{
            maxHeight: isOpen ? 40000 : 0,
            overflow: "hidden",
          }}
        >
          <div className="mt-4">
            <TimelineCycle
              events={[
                {
                  icon: "seedling",
                  name: "Définition des besoins",
                  description: "Passez en revue les critères que vous avez défini pour la réalisation de votre projet",
                  sections: [<NeedsFormsSection needsForms={project.needsForms} />],
                },
                {
                  icon: "file-signature",
                  name: "Signature du devis",
                  description: "Signez ou téléchargez votre devis",
                  sections: [<DevisSection quotation={project.quote} />],
                },
                {
                  icon: "folder-minus",
                  name: "Annexes",
                  description: "Ici vous pouvez échanger des fichiers avec Lithote",
                  sections: [
                    <AnnexesSection annexes={project.annexes} projectId={project.id} searchProjects={searchProjects} />,
                  ],
                },
                {
                  icon: "folder-open",
                  name: "Livrables",
                  description: "Tous les documents mis à votre disposition par Lithote",
                  sections: [<DocumentsSection documents={project.documents} searchProjects={searchProjects} />],
                },
                {
                  icon: "shopping-cart",
                  name: "Paiements",
                  description: "Liste des échéances de paiement",
                  sections: [<PaymentsSection payments={project.payments} />],
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  });
};

export default ProjectList;
