import { withRouter } from "react-router";
import FileUpload from "../../../../common/FileUpload/FileUpload";
import Loader from "../../../../common/Loader";

const DevisSection = ({ quotation }) => {
  return !quotation ? (
    <div>
      <small className="text-black-50">-aucun devis pour le moment-</small>
    </div>
  ) : (
    <div className="row mb-2">
      <div className="col-12 col-md-6">
        <div>
          <label>
            <span className="label-text">Lien vers le devis</span>
          </label>
        </div>
        <div>
          <a
            target="_BLANK"
            href={`https://${quotation.url}`}
            className="btn btn-link"
          >
            {quotation.url}
          </a>
        </div>
      </div>
      <div className="col-12 col-md-6">
        <div>
          <label>
            <span className="label-text">Devis signé</span>
          </label>
        </div>
        {quotation.file ? (
          <FileUpload
            existingFile={quotation.file}
            allFiles={[quotation.file]}
          />
        ) : (
          <div>
            <small className="text-black-50">-aucun fichier-</small>
          </div>
        )}
      </div>
    </div>
  );
};

export default DevisSection;
