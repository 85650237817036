import { Link } from "react-router-dom";
import UserBox from "../UserBox";

const DesktopMenu = ({
  items,
  activeLink,
  subMenu,
  subActiveLink,
  setActiveLink,
  setSubActiveLink,
  setSubMenu,
}) => {
  return (
    <nav
      id="sidebar"
      className={
        "noprint navbar navbar-expand-lg shadow-sm bg-dark text-white p-0 d-flex flex-column  w-100 shadow-sm"
      }
    >
      <div
        className="collapse navbar-collapse d-flex flex-row justify-content-start align-items-center  w-100 bg-dark "
        id="navbarSupportedContent"
        style={{
          transition: "400ms",
        }}
      >
        {/* <ul className="navbar-nav mr-auto">{getMenu(items)}</ul> */}
        <ul
          className="pl-0 top-menubar  mb-0 d-flex flex-wrap align-items-center"
          style={{ listStyle: "none", minHeight: 44 }}
        >
          {items.map((it, itk) => {
            var isActive = itk == activeLink;
            return (
              <li className="nav-item" key={`menuitem${itk}`}>
                <Link
                  to={it.path}
                  data-attr={isActive ? "active" : ""}
                  className={` nav-link font-weight-bold btn btn-sm mx-1 text-white ${
                    it.path == "#" ? "has-children" : ""
                  }`}
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                  }}
                  onClick={() => {
                    setActiveLink(itk);
                    if (it.path == "#") {
                      setSubMenu(it.items);
                    } else {
                      setSubMenu([]);
                    }
                  }}
                >
                  {it.path != "#" ? (
                    <i className={`fa fa-${it.icon} mr-2`} />
                  ) : null}
                  {it.name}
                  {/* {it.path == "#" ? (
                      <i className={`fa fa-${it.icon} ml-1`} />
                    ) : null} */}
                </Link>
              </li>
            );
          })}

          <li
            className="nav-item"
            style={{
              position: "absolute",
              right: 10,
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: "999",
            }}
          >
            <UserBox />
          </li>
        </ul>
      </div>
      {subMenu.length ? (
        <div className="w-100 d-center overflow-hidden ">
          <ul
            className=" px-2 mb-1 bottom-menubar d-flex flex-wrap"
            style={{ listStyle: "none" }}
          >
            {subMenu.map((it, itk) => {
              var isActive = itk == subActiveLink;
              return (
                <li
                  className="nav-item mt-2"
                  key={`submenuitem${itk}${it.path}`}
                >
                  <Link
                    to={it.path}
                    className={` nav-link mx-1 btn btn-sm btn-link animated fadeInDown`}
                    style={{
                      animationDelay: itk * 30 + "ms",
                      fontWeight: "500",
                      animationDuration: "200ms",
                    }}
                    onClick={() => setSubActiveLink(itk)}
                    data-attr={isActive ? "active" : ""}
                  >
                    <i className={`fa fa-${it.icon} mr-2`} />

                    {it.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
    </nav>
  );
};

export default DesktopMenu;
