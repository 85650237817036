import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import useStore from "../../../../../context/useStore";

const PaymentsSection = ({ payments }) => {
  const [state] = useStore();
  const { PAYMENT_STATUS, PAYMENT_METHODS } = state.constants.items;

  return payments.map((payment, pk) => {
    const paymentStatus = PAYMENT_STATUS.find((p) => p.id == payment.status);
    const paymentMethod = PAYMENT_METHODS.find(
      (p) => p.id == payment.paymentMethod
    );
    return (
      <div
        key={`payment${payment.id}`}
        className={`row py-2 ${
          pk == payments.length - 1 ? "" : "border-bottom"
        }`}
      >
        <div className="col-12 col-md-3 d-center justify-content-start font-weight-bold">
          <i className="fa fa-euro-sign mr-2 " />
          <span style={{ fontSize: 14 }}>{payment.name}</span>
        </div>
        <div className="col-12 col-md-3 d-center justify-content-start font-weight-bold">
          {Math.round((payment.amount / 100) * 100) / 100} €
        </div>
        <div
          className={`col-12 col-md-3 d-center justify-content-start text-${paymentStatus.color}`}
        >
          <i className={`fa fa-${paymentStatus.icon} mr-2`} />
          {paymentStatus.name}
          {payment.status == "DONE" && (
            <> le {DateTime.fromISO(payment.paidAt).toFormat("dd/MM/yyyy")}</>
          )}
        </div>
        <div className={`col-12 col-md-3 d-center justify-content-start`}>
          {paymentMethod && (
            <div>
              <i className={`fa fa-${paymentMethod.icon} mr-1`} />
              {paymentMethod.name}
            </div>
          )}
          {payment.status == "DONE" && paymentMethod && (
            <div>
              {payment.providerData && (
                <div className="ml-2">
                  <a
                    className="btn btn-link btn-sm d-center p-0"
                    target="_BLANK"
                    href={payment.providerData.receiptUrl}
                  >
                    <i className="fa fa-file mr-1" /> Reçu
                  </a>
                </div>
              )}
            </div>
          )}
          {payment.status == "WAITING" && !paymentMethod && (
            <Link
              className="btn btn-primary d-center"
              to={`/payments/${payment.id}`}
            >
              <i className="fa fa-lock mr-2" />
              PAYER
            </Link>
          )}
        </div>
      </div>
    );
  });
};

export default PaymentsSection;
