import useStore from "../../../../../context/useStore";
import useComments from "../../../../../hooks/useComments/useComments";
import FileUpload from "../../../../common/FileUpload/FileUpload";

const DocumentsSection = ({ documents, searchProjects }) => {
  const [state, dispatch] = useStore();
  const { openComments } = useComments(searchProjects);
  const { FILE_TYPES } = state.constants.items;
  var authorizedDocs = documents.filter((doc) => doc.authorizedToView);
  return authorizedDocs.map((document, dk) => {
    const fileType = FILE_TYPES.find((type) => type.id == document.fileType);

    return (
      <div
        className={`row ${
          dk == authorizedDocs.length - 1 ? "" : "border-bottom"
        } py-2`}
        key={`doc${document.id}`}
      >
        <div className="col-12 col-md-3 d-center justify-content-start font-weight-bold">
          <i className={`mr-2 fa-2x text-primary fa fa-${fileType.icon}`} />
          <span style={{ fontSize: 14 }}>{document.name}</span>
        </div>
        <div className="col-12 col-md-3 d-center justify-content-start">
          {document.isWatermarkVersion
            ? "Version temporaire"
            : "Version définitive"}
        </div>
        <div className="col-12 col-md-3 d-center justify-content-start">
          <FileUpload existingFile={document} allFiles={authorizedDocs} />
          <div className="d-flex justify-content-end h-100 align-items-end">
            <button
              className="btn  btn-sm position-relative"
              onClick={() => {
                if (document.comments) openComments(document);
                else return false;
              }}
            >
              <i className="fa fa-comment fa-2x" />
              <span
                className="position-absolute text-white font-weight-bold"
                style={{
                  left: "50%",
                  top: "45%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                {document?.comments?.length || 0}
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  });
};

export default DocumentsSection;
