import { useEffect } from "react";
import YouTube from "react-youtube-embed";
import { modalActions } from "../../../../context/actions/modalActions";
import useStore from "../../../../context/useStore";
import tools from "../../../../helpers/tools";
import VideoViewer from "./VideoViewer";

const PresentationVideo = () => {
  const [state, dispatch] = useStore();
  const mActions = modalActions(state, dispatch);
  var lsState = tools.getState("PresentationVideo", {
    videoWatched: { isSeen: false },
  });

  useEffect(() => {
    if (!lsState.videoWatched.isSeen) {
      tools.saveState("PresentationVideo", "videoWatched", { isSeen: true });
    }
  }, []);

  const openVideo = () => {
    mActions.updateModal({
      isOpen: true,
      content: <VideoViewer />,
    });
  };

  return (
    <button className="btn btn-link" onClick={openVideo}>
      <i className="fa fa-play mr-2" />
      Revoir la vidéo de présentation
    </button>
  );
};

export default PresentationVideo;
