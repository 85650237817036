import { Switch } from "react-router";
import FileViewer from "../../components/common/FileViewer/FileViewer";
import PrivateRoute from "../../components/common/PrivateRoute";
import Dashboard from "../../components/pages/Dashboard/Dashboard";
import Payment from "../../components/pages/Payment/Payment";
import Profile from "../../components/pages/Profile/Profile";

const privateRoutes = () => {
  return (
    <Switch>
      <PrivateRoute path="/" exact component={Dashboard} />
      <PrivateRoute path="/:projectId" exact component={Dashboard} />
      <PrivateRoute path="/dashboard" exact component={Dashboard} />
      <PrivateRoute path="/profile" exact component={Profile} />
      <PrivateRoute path="/fileviewer" exact component={FileViewer} />
      <PrivateRoute path="/payments/:id" exact component={Payment} />
    </Switch>
  );
};

export default privateRoutes;
