import React, { useState } from "react";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import { DateTime } from "luxon";
import colors from "../../../helpers/colors";

const TimelineEventCycle = ({ event, eventTitle }) => {
  var eventColor = colors.primary;

  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work cycle-timeline"
      contentStyle={{
        background: "#fff",
        color: "#404040",
        padding: 11,
        paddingTop: 4,
        paddingBottom: 5,
        boxShadow: "0 -3px 0 " + eventColor + " ,0 0 15px -3px #afafaf",
      }}
      contentArrowStyle={{ borderRight: "7px solid " + eventColor }}
      date={event.title}
      iconStyle={{
        background: eventColor,
        color: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 30,
        height: 30,
      }}
      icon={<i className={`fa fa-1x fa-${event.icon}`} />}
    >
      <div
        className="d-flex flex-column justify-content-start"
        style={{ fontSize: 12, listStyle: "none" }}
      >
        <div
          style={{ fontSize: 24, lineHeight: "24px" }}
          className="text-primary"
        >
          <strong>{event.name}</strong>
        </div>
        <div>
          <p className="text-black-50">
            <i>{event.description}</i>
          </p>
        </div>
        {event.sections}
      </div>
    </VerticalTimelineElement>
  );
};

export default TimelineEventCycle;
