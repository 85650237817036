import { useEffect, useState } from "react";
import useStore from "../../../../../context/useStore";
import Control from "../../../../common/Controls/Control";

const NeedsFormsSection = ({ needsForms }) => {
  const [state, dispatch] = useStore();
  const { FORM_TYPES } = state.constants.items;
  const services = FORM_TYPES.sort((a, b) => (a.name > b.name ? 1 : -1));
  const [hiddenFormTypes, setHiddenFormTypes] = useState([]);
  const [selectedServices, setSelectedServices] = useState(
    needsForms.map((ns) => ns.type)
  );

  useEffect(() => {
    setHiddenFormTypes(selectedServices);
  }, [selectedServices]);

  //on crée un array contenant les formulaires des services sélectionnés
  const forms = needsForms;

  const toggleHiddenFormTypes = (type) => {
    if (hiddenFormTypes.includes(type)) {
      setHiddenFormTypes(hiddenFormTypes.filter((h) => h != type));
    } else {
      setHiddenFormTypes([...hiddenFormTypes, type]);
    }
  };

  return (
    <div className="row">
      {forms.map((form, formk) => {
        const serviceName = services.find((s) => s.id == form.type).name;
        return (
          <div
            key={`formneed${formk}`}
            className={`col-12 col-md-6 mt-3 mb-2 vivify fadeInUp delay-${
              formk * 300
            }`}
            key={`formtemp${form.type}`}
          >
            <div className="">
              <div
                className="text-center bg-default text-white position-relative py-3 cursor-pointer"
                onClick={() => toggleHiddenFormTypes(form.type)}
              >
                <strong style={{ fontSize: 22 }}>{serviceName}</strong>
                {form.id && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 5,
                    }}
                  >
                    <small>enregistré</small>
                    <i className="fa fa-check ml-1" />
                  </div>
                )}
              </div>
              {form.sections.map((sec, seck) => (
                <div
                  key={`formneedsecto${formk}${seck}`}
                  className=""
                  style={{
                    maxHeight: hiddenFormTypes.includes(form.type) ? 0 : 1200,
                    overflow: "hidden",
                    transition: "400ms",
                  }}
                >
                  <div
                    className="bg-primary text-white font-weight-bold p-2"
                    style={{
                      fontSize: 18,
                    }}
                  >
                    {sec}
                  </div>
                  <div className="custom-card">
                    {form.inputs
                      .filter((inp) => inp.section == seck)
                      .map((input, inpk) => (
                        <div className="mb-3" key={`inp${formk}${seck}${inpk}`}>
                          <Control
                            disabled
                            change={(e) => {}}
                            {...input}
                            btnInline
                          />
                        </div>
                      ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default NeedsFormsSection;
