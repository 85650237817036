import Page from "../../common/layout/Page";
import useStore from "../../../context/useStore";
import ProjectList from "./ProjectList/ProjectList";
import useProject from "./ProjectList/useProject";
import PresentationVideo from "./PresentationVideo/PresentationVideo";

const Dashboard = (props) => {
  const [state, dispatch] = useStore();

  const { search, setSearch, searchProjects, projects, isLoading, setIsLoading, errors, nbPages } = useProject();

  return (
    <Page errors={errors} isLoading={isLoading}>
      <div className="mt-3">
        <PresentationVideo />
        <ProjectList
          props={{
            search,
            setSearch,
            searchProjects,
            projects,
            isLoading,
            setIsLoading,
            errors,
            openedProjectId: props.match.params.projectId,
          }}
        />
      </div>
    </Page>
  );
};

export default Dashboard;
