import axios from "axios";
import fileDownload from "js-file-download";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { API_URL } from "../../../config";
import dataService from "../../../helpers/dataService";
import ExistingFile from "./ExistingFile";

const FileUpload = ({
  noFixedHeight = false,
  onFileDrop,
  newBinary,
  clearNewFile,
  existingFile,
  deleteFile,
  autorizedToView = false,
  allFiles = [],
  updateAuthorized = () => {},
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const onDrop = (files) => {
    // Do something with the files

    if (files.length > 0) {
      onFileDrop(files[0]);
    } else {
      alert(
        "Le fichier choisi est incorrect. Les formats acceptés sont jpeg, png et pdf."
      );
    }
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const downloadFile = () => {
    setIsDownloading(true);
    axios({
      url: API_URL + `files/${existingFile.id}/download`,
      method: "GET",
      responseType: "blob",
    })
      .then((res) => {
        var filename = `${existingFile.name}${existingFile.fileExt}`;
        fileDownload(res.data, filename);
      })
      .catch((err) => {
        toast.error("Une erreur s'est produite");
      })
      .then(() => setIsDownloading(false));
  };

  return newBinary && !existingFile ? (
    <div>
      <div>{newBinary.name}</div>
      <button className="btn btn-default btn-sm" onClick={clearNewFile}>
        Annuler
      </button>
    </div>
  ) : existingFile ? (
    <div className="d-flex align-items-end flex-row">
      <ExistingFile
        existingFile={existingFile}
        deleteFile={deleteFile}
        downloadFile={downloadFile}
        isDownloading={isDownloading}
        allFiles={allFiles}
      />
    </div>
  ) : (
    <div
      className="d-center text-center p-3 w-100  bg-control my-3 cursor-pointer"
      style={{
        height: noFixedHeight ? "auto" : 300,
        border: "1px dashed black",
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Déposez le fichier ici ...</p>
      ) : (
        <div>
          Glissez-déposez un fichier ou cliquez pour parcourir vos dossiers
        </div>
      )}
    </div>
  );
};

export default FileUpload;
