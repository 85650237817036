import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useFile from "../../../../../hooks/useFile/useFile";

const useAnnexe = ({ annexes, projectId, searchProjects }) => {
  const { uploadFile, deleteFile } = useFile();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [existingFiles, setExistingFiles] = useState(annexes);
  const newFileInitialState = {
    name: "",
    type: "",
    binary: "",
  };
  const [newFile, setNewFile] = useState(newFileInitialState);
  const [binary, setBinary] = useState(null);
  useEffect(() => {
    setExistingFiles(annexes);
  }, [annexes]);

  const saveNewAnnexeFile = () => {
    setIsLoading(true);
    uploadFile({
      category: "annex",
      binary: newFile.binary,
      type: newFile.type,
      entity: projectId,
      name: newFile.name,
      onUploaded: (datas) => {
        setNewFile(newFileInitialState);
        toast.success("Votre fichier a bien été envoyé");
        searchProjects();
      },
      onError: setErrors,
      onFinished: () => setIsLoading(false),
    });
  };

  return {
    existingFiles,
    setExistingFiles,
    binary,
    setBinary,
    newFile,
    setNewFile,
    saveNewAnnexeFile,
    isLoading,
  };
};

export default useAnnexe;
