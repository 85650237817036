import useFileViewer from "../Hooks/useFileViewer";

const VideoItem = ({ file, allFiles }) => {
  const { openFileViewer } = useFileViewer();
  const openFile = () => {
    openFileViewer(file.id, allFiles);
  };

  return (
    <div className="d-center flex-row">
      <button
        className="btn  btn-sm "
        onClick={openFile}
        title="Voir le fichier"
      >
        <i className="fa fa-eye fa-2x" />
      </button>
      <a
        href={`https://www.youtube.com/watch?v=${file.youtubeId}`}
        target="_BLANK"
        title="Voir sur Youtube"
        className="btn btn-sm"
      >
        <i className="fa fa-youtube fa-2x" />
      </a>
    </div>
  );
};

export default VideoItem;
