import React from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import UserBox from "../UserBox";
import DesktopMenu from "./DesktopMenu";
import "./topmenu.scss";
import useTopMenu from "./useTopMenu";
import MobileMenu from "./MobileMenu/MobileMenu";

const TopMenu = withRouter((props) => {
  const {
    state,
    authData,
    authActions,
    items,
    activeLink,
    subActiveLink,
    setActiveLink,
    setSubActiveLink,
    actions,
    setSubMenu,
    subMenu,
    isMobile,
  } = useTopMenu(props);

  return authData.isAuthenticated && !authData.user.mustRenewPassword ? (
    isMobile ? (
      <>
        <MobileMenu items={items} activeLink={activeLink} />
        <div className="mb-5 pb-5"></div>
      </>
    ) : (
      <DesktopMenu
        items={items}
        activeLink={activeLink}
        subMenu={subMenu}
        subActiveLink={subActiveLink}
        setActiveLink={setActiveLink}
        setSubActiveLink={setSubActiveLink}
        setSubMenu={setSubMenu}
      />
    )
  ) : (
    <></>
  );
});

export default TopMenu;
