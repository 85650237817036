import { modalActions } from "../../../context/actions/modalActions";
import useStore from "../../../context/useStore";
import FilePreview from "../FileViewer/FilePreview";

const useFileViewer = () => {
  const [state, dispatch] = useStore();
  const mActions = modalActions(state, dispatch);

  const openFileViewer = (currentFileId = null, items) => {
    mActions.updateModal({
      isOpen: true,
      content: <FilePreview currentFileId={currentFileId} filesArray={items} />,
    });
  };
  return { openFileViewer };
};

export default useFileViewer;
