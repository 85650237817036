import { IbanElement } from "@stripe/react-stripe-js";

const IBAN_STYLE = {
  base: {
    color: "#32325d",
    fontFamily: "Monserrat, sans-serif",
    fontSmoothing: "antialiased",
    lineHeight: "26px",
    fontSize: "16px",
    "::placeholder": {
      color: "#aab7c4",
    },
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a",
  },
};

const INPUT_STYLE = {
  color: "#32325d",
  fontFamily: "Monserrat, sans-serif",
  fontSmoothing: "antialiased",
  lineHeight: "26px",
  fontSize: "16px",
};
const INPUT_CONTAINER_STYLE = {
  padding: 10,
  height: 48,
  width: "100%",
  borderRadius: 4,
  border: "2px solid #c0c6d5",
  margin: "10px 0",
};

const IBAN_ELEMENT_OPTIONS = {
  supportedCountries: ["SEPA"],
  // Elements can use a placeholder as an example IBAN that reflects
  // the IBAN format of your customer's country. If you know your
  // customer's country, we recommend that you pass it to the Element as the
  // placeholderCountry.
  placeholderCountry: "FR",
  style: IBAN_STYLE,
};

const SepaForm = ({ userAdditionalData, setUserAdditionalData }) => {
  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <div>
          <label className="w-100">
            Adresse 1
            <div style={INPUT_CONTAINER_STYLE}>
              <input
                style={{
                  width: "100%",
                  border: 0,
                }}
                type="text"
                value={userAdditionalData.address1}
                onChange={(e) =>
                  setUserAdditionalData({
                    ...userAdditionalData,
                    address1: e.target.value,
                  })
                }
              />
            </div>
          </label>
        </div>
      </div>
      <div className="col-12 col-md-6">
        <div>
          <label className="w-100">
            Adresse 2
            <div style={INPUT_CONTAINER_STYLE}>
              <input
                style={{
                  width: "100%",
                  border: 0,
                }}
                type="text"
                value={userAdditionalData.address2}
                onChange={(e) =>
                  setUserAdditionalData({
                    ...userAdditionalData,
                    address2: e.target.value,
                  })
                }
              />
            </div>
          </label>
        </div>
      </div>
      <div className="col-12 col-md-6">
        <div>
          <label className="w-100">
            Code postal
            <div style={INPUT_CONTAINER_STYLE}>
              <input
                style={{
                  width: "100%",
                  border: 0,
                }}
                placeholder="ex : 97400"
                pattern="[0-9]{5}"
                type="text"
                value={userAdditionalData.postalCode}
                onChange={(e) =>
                  setUserAdditionalData({
                    ...userAdditionalData,
                    postalCode: e.target.value,
                  })
                }
              />
            </div>
          </label>
        </div>
      </div>
      <div className="col-12 col-md-6">
        <div>
          <label className="w-100">
            Ville
            <div style={INPUT_CONTAINER_STYLE}>
              <input
                style={{
                  width: "100%",
                  border: 0,
                }}
                type="text"
                value={userAdditionalData.city}
                onChange={(e) =>
                  setUserAdditionalData({
                    ...userAdditionalData,
                    city: e.target.value,
                  })
                }
              />
            </div>
          </label>
        </div>
      </div>
      <div className="col-12">
        <label className="w-100">
          Numéro IBAN
          <IbanElement options={IBAN_ELEMENT_OPTIONS} />
        </label>
      </div>
    </div>
  );
};

export default SepaForm;
