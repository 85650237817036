import colors from "../../../helpers/colors";
import useFileViewer from "../Hooks/useFileViewer";
import Loader from "../Loader";

const ExistingFile = ({
  existingFile,
  downloadFile,
  isDownloading,
  allFiles,
}) => {
  const { openFileViewer } = useFileViewer();
  const openFile = () => {
    openFileViewer(existingFile.id, allFiles);
  };

  return (
    <div className="d-flex flex-column  align-items-start">
      <div className="mb-2 ">
        <small>
          <i>
            {existingFile.name}
            {existingFile.fileExt}
          </i>
        </small>
      </div>
      <div className="d-center flex-row">
        {existingFile.fileType != "OTHER" && (
          <button
            className="btn  btn-sm border-right-0"
            onClick={openFile}
            title="Voir le fichier"
          >
            <i className="fa fa-eye fa-2x" />
          </button>
        )}
        <button
          className="btn  btn-sm"
          onClick={downloadFile}
          title="Télécharger le fichier"
        >
          {isDownloading ? (
            <Loader color={colors.primary} />
          ) : (
            <i className="fa fa-download fa-2x" />
          )}
        </button>
      </div>
    </div>
  );
};

export default ExistingFile;
