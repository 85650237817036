import { toast } from "react-toastify";
import dataService from "../../../../../helpers/dataService";
import { getInitConfig } from "../initConfig";

export const itemsActions = (state, dispatch, mActions) => {
  const initItems = async (fetchId) => {
    const initConfig = getInitConfig(fetchId);
    dispatch({ type: "IS_LOADING", payload: true });
    var allPromises = [];
    var actionUrls = {};
    var items = {};
    initConfig.forEach((url) => {
      actionUrls[url.name] = { ...url.paths };
      if (url.paths?.get && url.fetchOnLoad) {
        allPromises.push(
          new Promise((resolve, reject) => {
            dataService.get(url.paths.get, resolve, reject);
          })
        );
      }
      if (!url.fetchOnLoad) {
        allPromises.push(
          new Promise((resolve, reject) => {
            if (Array.isArray(url.initialState)) {
              resolve([...url.initialState]);
            } else {
              resolve({ ...url.initialState });
            }
          })
        );
        // items[url.name] = url.initialState;
        // console.log("it", items);
      }
    });
    if (allPromises.length) {
      Promise.all(allPromises)
        .then((values) => {
          values.forEach((value, key) => {
            items[initConfig[key].name] = value;
          });

          dispatch({
            type: "INIT_ITEMS",
            payload: { items, actionUrls },
          });
        })
        .catch((err) => {
          console.log("error", err);
          Object.values(err).forEach((er) => toast.error(er));
        });
    } else {
      var defaultItems = {};
      initConfig.forEach((url) => {
        defaultItems[url.name] = url.initialState;
      });

      dispatch({
        type: "INIT_ITEMS",
        payload: { items: defaultItems, actionUrls },
      });
    }
  };

  const updateItemObject = (itemName, e) => {
    const { name, value, type, checked, k } = e.target;
    var newItem = { ...state.items[itemName] };

    if (type == "checkbox") {
      newItem[name] = checked ? true : false;
    } else {
      newItem[name] = value;
    }

    dispatch({
      type: "UPDATE_ITEM",
      payload: {
        item: itemName,
        value: newItem,
      },
    });
  };

  const setIsLoading = (type = null, isLoading) => {
    dispatch({
      type: `IS_LOADING${type ? "_" + type : ""}`,
      payload: isLoading,
    });
  };

  const setPaymentMethod = (id) => {
    dispatch({
      type: "UPDATE_PAYMENT_METHOD",
      payload: id,
    });
  };

  const setErrors = (errors) => {
    dispatch({
      type: "UPDATE_ERRORS",
      payload: errors,
    });
  };

  const setPaymentIntent = (paymentIntent) => {
    dispatch({
      type: "UPDATE_PAYMENT_INTENT",
      payload: paymentIntent,
    });
  };
  const setPaymentOk = (paymentOk) => {
    dispatch({
      type: "UPDATE_PAYMENT_OK",
      payload: paymentOk,
    });
  };

  return {
    initItems,
    updateItemObject,
    setErrors,
    setIsLoading,
    setPaymentMethod,
    setPaymentIntent,
    setPaymentOk,
  };
};
