import axios from "axios";
import { API_URL } from "../../config";
import tools from "../../helpers/tools";

export const constantActions = (state, dispatch, props) => {
  function getConstants() {
    dispatch({ type: "LOAD_CONSTANT", payload: true });

    axios
      .get(API_URL + "constants/BOC")
      .then((res) => {
        dispatch({ type: "GET_CONSTANTS", payload: res.data });
      })
      .catch((err) => {
        console.log(err);
        var err = tools.filterError(err, dispatch, "LOAD_CONSTANT");
      });
  }

  return {
    getConstants,
  };
};
