import { Link } from "react-router-dom";

const PaymentInvalid = () => {
  return (
    <div className="d-center w-100 custom-card flex-column">
      <div>Cette échéance a expiré.</div>
      <Link to="/" className="btn btn-link">
        Revenir à l'accueil
      </Link>
    </div>
  );
};

export default PaymentInvalid;
