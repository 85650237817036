import ItemStore from "./Context/ItemStore";
import PaymentPage from "./PaymentPage";
import itemReducer from "./Context/reducers/itemsReducer";
import PaymentForm from "./PaymentForm";

const Payment = (props) => {
  return (
    <ItemStore rootReducer={itemReducer}>
      <PaymentPage>
        <PaymentForm />
      </PaymentPage>
    </ItemStore>
  );
};

export default Payment;
