import { useEffect, useState } from "react";
import dataService from "../../../../helpers/dataService";

var searchTimeout = null;
var isFirstLoad = true;

const useProject = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [search, setSearch] = useState({
    searchString: "",
    project: {},
    page: 1,
  });
  const [projects, setProjects] = useState([]);
  const [errors, setErrors] = useState({});
  const [nbPages, setNbPages] = useState(1);

  const searchProjects = () => {
    setIsLoading(true);
    dataService.get(
      `dashboard`,
      (datas) => {
        setProjects(datas);
      },
      setErrors,
      () => setIsLoading(false)
    );
  };

  useEffect(() => {
    searchProjects();
  }, []);

  return {
    searchProjects,
    projects,
    isLoading,
    setIsLoading,
    nbPages,
  };
};

export default useProject;
