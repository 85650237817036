import { DateTime } from "luxon";
import { useEffect } from "react";
import useStore from "../../../context/useStore";

const Conversation = ({ comments, isOpen }) => {
  const [state] = useStore();
  const { COMMENT_STATUSES } = state.constants.items;
  var commentsColors = [];
  var colors = [
    "primary",
    "info",
    "success",
    "danger",
    "warning",
    "tertiary",
    "dark",
  ];
  var incr = 0;
  comments.forEach((comment) => {
    if (!commentsColors.find((c) => c.commentedBy == comment.commentedBy.id)) {
      commentsColors.push({
        commentedBy: comment.commentedBy.id,
        color: colors[incr],
      });
      incr++;
    }
  });

  useEffect(() => {
    setTimeout(() => {
      scrollBottom();
    }, 500);
  }, []);

  useEffect(() => {
    scrollBottom();
  }, [comments, isOpen]);

  const scrollBottom = () => {
    var objDiv = document.getElementById("conversation");
    if (objDiv) {
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  };

  return (
    <div
      style={{
        maxHeight: 400,
        overflowY: "scroll",
      }}
      id="conversation"
    >
      {comments.map((comment) => {
        var color = commentsColors.find(
          (c) => c.commentedBy == comment.commentedBy.id
        ).color;
        var status = COMMENT_STATUSES.find((st) => st.id == comment.status);
        return (
          <div className={`bg-${color} rounded text-white p-2 my-2`}>
            <p>
              <span className="mr-2">{comment.commentedBy.fullName} :</span>
              <span className="font-weight-bold">{comment.content}</span>
            </p>
            <div className="d-flex justify-content-between mt-2 align-items-end">
              <small className="mr-2">
                {comment.commentedByRef != "UserManager" && (
                  <>
                    <i className={`fa fa-${status.icon} mr-2`} />
                    {status.name}
                  </>
                )}
              </small>

              <small>
                {" "}
                <small className="d-block text-right" style={{ fontSize: 9 }}>
                  {DateTime.fromISO(comment.commentedAt).toFormat(
                    "dd/MM HH'h'mm"
                  )}
                </small>
              </small>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Conversation;
