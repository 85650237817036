import React, { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import { authActions } from "../../../../../context/actions/authActions";
import useStore from "../../../../../context/useStore";
import UserBox from "../../UserBox";
import "./mobilemenu.scss";

const MobileMenu = ({ items, activeLink }) => {
  const [state, dispatch] = useStore();
  const auth_actions = authActions(state, dispatch);

  const [isOpen, setIsOpen] = useState(false);

  const handleMenuClick = (event) => {
    event.preventDefault();
    // Using the parent component's state to keep track of the menu
    setIsOpen(false);
  };

  return (
    <>
      <UserBox
        style={{ position: "absolute", zIndex: "9999", top: 18, right: 24 }}
      />
      <Menu
        isOpen={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
      >
        {items.map((item, itk) => (
          <Link
            key={`mobile-menu-item${item.path}`}
            onClick={() => setIsOpen(false)}
            className={`menu-item ${activeLink == itk ? "active" : ""}`}
            to={item.path}
          >
            {items.path != "#" && <i className={`fa fa-${item.icon} mr-3`} />}
            {item.name}
          </Link>
        ))}
        <button
          className={`menu-item btn text-primary mt-5 pt-4 border-top w-100`}
          style={{
            position: "absolute",
            bottom: 6,
            left: 0,
          }}
          onClick={auth_actions.logoutUser}
        >
          {/* <i className={`fa fa-sign-out-alt mr-3`} /> */}
          Déconnexion
        </button>
      </Menu>
    </>
  );
};

export default MobileMenu;
