import React, { useContext, useState, useEffect } from "react";
import useStore from "../../../context/useStore";
import { authActions } from "../../../context/actions/authActions";
import ForgotPassword from "./ForgotPasswordForm/ForgotPassword";
import { ReCaptcha } from "react-recaptcha-v3";
import { recaptcha_token, APP_NAME } from "../../../config";
import LoginForm from "./LoginForm/LoginForm";
import { Link } from "react-router-dom";

var timerInterval = null;
var recaptcha;

const Login = () => {
  const [state, dispatch] = useStore();
  var actions = authActions(state, dispatch);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(0);
  const [captchaToken, setCaptchaToken] = useState(false);
  const [register, setRegister] = useState(false);

  const [canLoggin, setCanLoggin] = useState(true);

  useEffect(() => {
    document.querySelector(".login-form").scrollIntoView();
  }, []);

  useEffect(() => {
    if (Object.values(state.errors).length > 0) {
      setIsLoading(false);
      if (state.errors.password) {
        setPassword("");
      }
      if (state.errors.timer) {
        clearInterval(timerInterval);
        setTimer(parseInt(state.errors.timer));
      }
    }
  }, [state.errors]);

  useEffect(() => {
    setTimer(false);
    setCanLoggin(true);
    setCaptchaToken(false);
    updateToken();
  }, [forgotPassword]);

  function loginHandle(e) {
    e.preventDefault();

    setIsLoading(true);
    //actions.loginUser({ login, password });
  }

  useEffect(() => {
    if (timer > 0) {
      setCanLoggin(false);
    }
  }, [timer]);

  function timerFinish() {
    setCanLoggin(true);
  }

  const verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!

    actions.loginUser({ login, password, captchaToken: recaptchaToken });
    setCaptchaToken(recaptchaToken);
  };

  const updateToken = () => {
    // you will get a new token in verifyCallback
    if (recaptcha) {
      recaptcha.execute();
    }
  };

  return (
    <div
      className="container-fluid h-100 d-center w-100 flex-column"
      style={{ minHeight: "100vh" }}
    >
      <div className="row w-100">
        <div
          className="col-12 px-0 mx-auto"
          style={{
            maxWidth: 400,
          }}
        >
          <img src="/images/logo.svg" />
          <div className="custom-card overflow-hidden pt-0">
            {forgotPassword ? (
              <ForgotPassword
                setForgotPassword={setForgotPassword}
                captchaToken={captchaToken}
              />
            ) : (
              <>
                <LoginForm
                  login={login}
                  setLogin={setLogin}
                  password={password}
                  setPassword={setPassword}
                  loginHandle={loginHandle}
                  canLoggin={canLoggin}
                  timer={timer}
                  timerFinish={timerFinish}
                  isLoading={isLoading}
                />
                <div className="w-75 mx-auto d-flex justify-content-center mt-3 mt-2">
                  <button
                    type="button"
                    style={{ fontSize: 12 }}
                    onClick={() => setForgotPassword(true)}
                    className="btn btn-link p-0"
                  >
                    mot de passe oublié ?
                  </button>
                </div>
              </>
            )}
          </div>
        </div>

        {isLoading && (
          <ReCaptcha
            ref={(ref) => (recaptcha = ref)}
            sitekey={recaptcha_token}
            action="login"
            verifyCallback={verifyCallback}
          />
        )}
      </div>
      <div
        className="mt-4 text-center px-3 w-100 d-center"
        style={{
          fontSize: 9,
          right: 0,
        }}
      >
        En vous connectant, vous acceptez nos{" "}
        <Link
          to="/cgu"
          className="btn btn-link btn-sm p-0 ml-1"
          style={{ fontSize: 9, lineHeight: 0, fontWeight: "400" }}
        >
          conditions générales d'utilisation
        </Link>
        .
      </div>
      <div
        className="mt-4 text-center px-3"
        style={{
          fontSize: 9,
          right: 0,
        }}
      >
        Ce site est protégé par reCAPTCHA, la{" "}
        <a href="https://policies.google.com/privacy">
          Politique de confidentialité
        </a>{" "}
        de Google ainsi que ses{" "}
        <a href="https://policies.google.com/terms">Conditions d'utilisation</a>{" "}
        s'appliquent.
      </div>
    </div>
  );
};

export default Login;
