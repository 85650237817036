import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { authActions } from "../../../context/actions/authActions";
import useStore from "../../../context/useStore";

const UserBox = ({ style = { position: "relative" } }) => {
  const [state, dispatch] = useStore();
  const auth_actions = authActions(state, dispatch);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.addEventListener("click", documentClick);
  }, []);

  const documentClick = (e) => {
    if (e.target.id != "openUserBox") {
      setIsOpen(false);
    }
  };

  return (
    <div style={{ ...style }}>
      <i
        id="openUserBox"
        className="fa fa-user-circle text-white cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
        style={{ fontSize: 28 }}
      />

      <div
        className={`position-absolute shadow-sm animated faster ${
          isOpen ? "fadeInUp" : "d-none"
        }`}
        style={{
          width: 132,
          right: -11,
          top: 39,
          zIndex: "999",
        }}
      >
        <ul className="list-group">
          <li className="list-group-item bg-white border-0 w-100 p-0">
            <Link
              to="/profile"
              className="btn btn-link text-left text-default w-100"
            >
              Mon profil
            </Link>
          </li>
          <li className="list-group-item bg-white border-0 w-100 p-0">
            <button
              className="btn btn-link text-left text-default w-100"
              onClick={auth_actions.logoutUser}
            >
              Se déconnecter
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default UserBox;
