import { DateTime } from "luxon";
import FileUpload from "../../../../common/FileUpload/FileUpload";
import Table from "../../../../common/Table/Table";
import VideoItem from "../../../../common/VideoItem/VideoItem";
import tools from "../../../../../helpers/tools";
import useStore from "../../../../../context/useStore";

const AnnexeList = ({ existingFiles, setExistingFiles, deleteAnnexeFile }) => {
  const [state] = useStore();
  const { FILE_TYPES } = state.constants.items;

  const clearFile = (file) => {
    setExistingFiles(
      existingFiles.map((ex) =>
        ex.id == file.id
          ? {
              ...ex,
              binary: null,
            }
          : ex
      )
    );
  };

  const addNewBinary = (file, binary) => {
    setExistingFiles(
      existingFiles.map((ex) =>
        ex.id == file.id
          ? {
              ...ex,
              binary,
            }
          : ex
      )
    );
  };

  return existingFiles.map((file, fk) => {
    const fileType = FILE_TYPES.find((type) => type.id == file.fileType);
    return (
      <div
        className={`row py-2 ${
          fk == existingFiles.length - 1 ? "" : "border-bottom"
        }`}
      >
        <div className="col-12 col-md-3 d-center justify-content-start font-weight-bold">
          <i className={`mr-2 fa-2x text-primary fa fa-${fileType.icon}`} />
          <span style={{ fontSize: 14 }}>{file.name}</span>
        </div>
        <div className="col-12 col-md-3 d-center justify-content-start">
          <div>
            <div>
              le {DateTime.fromISO(file.uploadedAt).toFormat("dd/MM/yyyy")}
            </div>
            <small>par {tools.getFullName(file.uploadedBy)}</small>
          </div>
        </div>

        <div className="col-12 col-md-3 d-center justify-content-start">
          {file.fileType == "VIDEO" ? (
            <VideoItem file={file} allFiles={existingFiles} />
          ) : (
            <FileUpload
              existingFile={file}
              newBinary={file.binary}
              clearNewFile={() => clearFile(file)}
              onFileDrop={addNewBinary}
              deleteFile={() => {}}
              allFiles={existingFiles}
            />
          )}
        </div>
      </div>
    );
  });
};

export default AnnexeList;
