const initialState = {
  isLoading: false,
  isLoadingPaying: false,
  isLoadingStripe: false,
  items: {},
  actionUrls: {},
  errors: {},
  isInit: false,
};

const initItems = (payload, state) => {
  const { items, actionUrls } = payload;
  return { ...state, items, actionUrls, isLoading: false, isInit: true };
};

// const addItem = (item, state) => {
//   const newItem = [...state.items, item];
//   return { ...state, items: newItem };
// };
// const removeItem = (idx, state) => {
//   const newItems = [...state.items].filter((t, tk) => tk != idx);
//   return { ...state, items: newItems };
// };
const updateItem = ({ item, value }, state) => {
  return { ...state, items: { ...state.items, [item]: value } };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "IS_LOADING":
      return { ...state, isLoading: action.payload };
    case "IS_LOADING_PAYING":
      return { ...state, isLoadingPaying: action.payload };
    case "IS_LOADING_STRIPE":
      return { ...state, isLoadingStripe: action.payload };

    case "INIT_ITEMS":
      return initItems(action.payload, state);
    case "IS_INIT":
      return { ...state, isInit: action.payload };

    case "UPDATE_ITEM":
      return updateItem(action.payload, state);
    case "UPDATE_ERRORS":
      return { ...state, items: { ...state.items, errors: action.payload } };
    case "UPDATE_PAYMENT_METHOD":
      return {
        ...state,
        items: {
          ...state.items,
          utils: { ...state.items.utils, paymentMethod: action.payload },
        },
      };
    case "UPDATE_PAYMENT_INTENT":
      return {
        ...state,
        items: {
          ...state.items,
          utils: { ...state.items.utils, paymentIntent: action.payload },
        },
      };
    case "UPDATE_PAYMENT_OK":
      return {
        ...state,
        items: {
          ...state.items,
          utils: { ...state.items.utils, paymentOk: action.payload },
        },
      };
    default:
      return state;
  }
};
