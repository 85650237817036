import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router";
import Page from "../../common/layout/Page";
import { initConfig } from "./Context/initConfig";
import useItemStore from "./Context/useItemStore";
import useStoreState from "./Context/useStoreState";

const PaymentPage = withRouter((props) => {
  const { state, items, items_actions } = useStoreState();
  const fetchId = props.match.params.id;
  const { payment } = items;

  console.log("state local", state);
  useEffect(() => {
    items_actions.initItems(fetchId);
  }, []);

  return (
    <Page
      title={
        payment
          ? `Paiement de l'échéance "${payment.name}"`
          : `Paiement d'une échéance`
      }
      isLoading={state.isLoading}
      back={"/"}
    >
      {state.isInit ? props.children : null}
    </Page>
  );
});

export default PaymentPage;
