import React, { useContext, useState, useEffect } from "react";
import Control from "../../../common/Controls/Control";
import SaveBtn from "../../../common/SaveBtn/SaveBtn";
import tools from "../../../../helpers/tools";
import LoginErrorBox from "../LoginErrorBox";
import useStore from "../../../../context/useStore";

const LoginForm = ({
  loginHandle,
  login,
  setLogin,
  password,
  setPassword,
  canLoggin,
  timer,
  timerFinish,
  isLoading,
}) => {
  const [state, dispatch] = useStore();

  return (
    <form
      onSubmit={loginHandle}
      className="d-flex align-items-center justify-content-start flex-column mx-auto animated fadeInLeft faster login-form"
    >
      <div className=" text-center">
        <h1 className="py-4 text-secondary main-title">ESPACE CLIENT</h1>
      </div>
      <Control
        className="w-75"
        autoFocus={true}
        label="Email"
        name="login"
        id="login"
        type="email"
        value={login}
        change={(e) => setLogin(e.target.value)}
        error={state.errors}
        isValid={tools.validateEmail(login)}
      />
      <div className="w-75 m-auto">
        <Control
          className="w-100"
          label="Mot de passe"
          name="password"
          id="password"
          type="password"
          value={password}
          change={(e) => setPassword(e.target.value)}
          error={state.errors}
          isValid={password.length > 6}
          showValidation={false}
        />
      </div>
      <LoginErrorBox
        timer={timer}
        timerFinish={timerFinish}
        canLoggin={canLoggin}
      />
      <div className="w-75 mx-auto d-flex justify-content-center mt-3">
        {canLoggin && (
          <SaveBtn
            className="animated zoomIn"
            isSaving={isLoading}
            text="Je me connecte"
            type="primary"
            typeBtn="submit"
            save={() => {}}
          />
        )}
      </div>
    </form>
  );
};

export default LoginForm;
