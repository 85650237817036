import React, { useState } from "react";
import Control from "../../../common/Controls/Control";
import { PLATFORM } from "../../../../config";
import SaveBtn from "../../../common/SaveBtn/SaveBtn";
import dataService from "../../../../helpers/dataService";
import MailSuccess from "./MailSuccess";

const ForgotPassword = ({ setForgotPassword, captchaToken }) => {
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [errors, setErrors] = useState({});
  const [checkingEmail, setCheckingEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  function checkEmail(e) {
    e.preventDefault();
    setCheckingEmail(true);
    dataService.post(
      `auth/renew-password/${PLATFORM}`,
      {
        email,
      },
      (datas) => {
        setEmailSent(true);
      },
      setErrors,
      () => setCheckingEmail(false)
    );
  }

  return (
    <form
      onSubmit={checkEmail}
      className="d-flex align-items-center justify-content-start flex-column mx-auto animated fadeInRight faster login-form "
    >
      <div className=" text-center">
        <h1 className="py-4 text-secondary main-title">MOT DE PASSE OUBLIÉ</h1>
      </div>
      {emailSent ? (
        <MailSuccess setForgotPassword={setForgotPassword} />
      ) : (
        <>
          <p className="mb-4 w-75 text-left">
            Une fois ce formulaire rempli, vous recevrez un email contenant un
            lien qui vous permettra de définir un nouveau mot de passe pour
            votre compte.
          </p>
          <Control
            label="Entrez votre email"
            className="w-75"
            value={email}
            type="email"
            change={(e) => setEmail(e.target.value)}
            name="email"
            error={errors}
            required
            setIsValid={(isValid) => {
              setEmailValid(isValid);
            }}
          />

          <div className="w-75 mx-auto d-flex justify-content-center mt-3">
            {
              <SaveBtn
                className="animated zoomIn"
                isSaving={checkingEmail}
                disabled={!emailValid}
                save={checkEmail}
                text="Recevoir le lien"
                type="primary"
                typeBtn="submit"
              />
            }
          </div>
          <div className="w-75 mx-auto d-flex justify-content-center mt-3">
            <button
              onClick={() => setForgotPassword(false)}
              style={{ fontSize: 12 }}
              className="btn btn-link text-secondary p-0"
            >
              <i className="fa fa-chevron-left mr-2" />
              Revenir à la connexion
            </button>
          </div>
        </>
      )}
    </form>
  );
};

export default ForgotPassword;
